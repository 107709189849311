// This file is automatically compiled by Webpack, along with any other files
/*eslint import/no-unresolved: off*/

// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
//require.context('../../assets/images', true)
//const images = require.context('../../assets/images', true)
//const imagePath = (name) => images(name, true)

import 'compact/default/index.js'
import 'stylesheets/application.scss'
import 'intl-tel-input/build/css/intlTelInput.css'
import 'swiper/css'

import 'lazysizes'

window.Alpine.start()
